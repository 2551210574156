(function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);throw new Error("Cannot find module '"+o+"'")}var f=n[o]={exports:{}};t[o][0].call(f.exports,function(e){var n=t[o][1][e];return s(n?n:e)},f,f.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({1:[function(require,module,exports){
;
(function ($) {

	// Scripts which runs after DOM load

	$(document).ready(function () {
        // $('.searchform button').hover(function(){
		// 	// $('.header .searchform input').animate().css('display','block');
		// 	$('.header .searchform input').animate().css('width','150px');
		// })
		// $('.header').on('click', function (e) {
		// 	if ($(e.target).closest("#searchform").length === 0) {
		// 		$('.header .searchform input').animate().css('width','0px');
		// 	}
		// });

		$('body').on('click', '.search-icon', function (e) {
			console.log('search');
			$('.header__form').toggleClass('active');
		});

        var site_url = $('.poland-map-section').attr('data-site-url') ;
        // console.log(site_url) ;


        if ($(".map-poland1").length > 0){
            var select_area = false;
            $(".map-poland1 area").on('click',function(e) {
                var href = $(this).attr("alt");

                $("#map-poland-contact2").attr("src", site_url+"/acf-blocks/poland-map/img/"+href);

                var show = $(this).data("region");
                console.log($("#"+show));
                // if ($("#"+show).is(":visible")){
                // }
                // else {
                    $(".poland-region-info").slideUp();
                    $("#"+show).slideDown();
                    // $("#region-5").slideDown();
                    // $("html, body").animate({ scrollTop: $('#'+show).offset().top -50 }, 1000);
                    // var h = $(".map-poland1").offset().top + $(".map-poland1").height();
                    // $('html, body').stop().delay(500).animate({scrollTop: h});
                // }
                select_area = true;
                return false
            });
            $(".map-poland1 area").hover(function(e) {
                if (!select_area){
                    var href = $(this).attr("alt");

                    $("#map-poland-contact2").attr("src", site_url+"/acf-blocks/poland-map/img/"+href);

                    return false
                }

            });
            $(".map-poland1 area").mouseleave(function(e) {
                if (!select_area){
                    $("#map-poland-contact2").attr("src", site_url+"/acf-blocks/poland-map/img/map-clear3.png");
                }
            });
        }




    //     function rimClick() {
    //        $('.search-products-single > a').click(function(){
    //         var slug = $(this).data('slug');

    //         localStorage.setItem('rim', slug);
    //     });
    //    }
    //    rimClick();

       $('#nav-icon2').click(function(){
           $(this).toggleClass('open');
           $('.top-bar').toggleClass('open');
           $('.header').toggleClass('open-header');
           $('.body-cover').toggleClass('open');
       });

       $('.body-cover').click(function(){
           $(this).toggleClass('open');
           $('.top-bar').toggleClass('open');
           $('.header').toggleClass('open-header');
           $('#nav-icon2').toggleClass('open');
       });

       $('.open-submenu').on('click',function(e) {
           e.preventDefault();
           $(this).toggleClass('active').next('.submenu').slideToggle();
           $(this).closest('li').find('> a').toggleClass('focused')
       });


       $('.accordion-item-title').click(function() {
            // var plus = $(this).children('i');
            var content = $(this).next('.accordion-item-content');
            
            $(this).toggleClass('active');
            content.slideToggle();
        });

       $('[data-fancybox],.wp-block-image a, a[href$=".jpg"], a[href$=".png"]').fancybox({
           buttons: [
    		// "zoom",
    		"share",
    		"slideShow",
    		"fullScreen",
    		// "download",
    		"thumbs",
    		"close"
    		],
    		thumbs: {
    			autoStart: true, 
    		},
		   afterShow: function( instance, current ) {
			   var image = current.opts.$orig;
			   var code = image.attr('data-code');
			   code = (typeof code != 'undefined' && code.length > 0) ? code : '';
			   var title = image.attr('data-caption');
			   var content = $(current.$content);
			   content.append('<div class="fancybox-tmp-box"><span class="fancybox-tmp-title">' + title + '</span><span class="fancybox-tmp-code">' + code + '</span></div>');
		   }
    	});

    	// $('.career-filter-select-category').on('change', function() {
    	// 	$('.accordion-item').slideUp();
    	// 	$('.'+this.value).slideDown() ;
    	// });
    	
    	var date_item = $('.accordion-item') ;
    	var item_container = $("#post-type-career-list") ;


    	$('.career-filter-select-date').on('input', function() { 
			
			var old = $(this).attr('data-old');
			if(this.value == 'latest' && old != 'latest'){
				$('.accordion-item').sort(function(a,b){
					return parseInt($(b).data("careerdate")) < parseInt($(a).data("careerdate"));
				}).each(function(){
					$("#post-type-career-list").prepend(this);
				});
				$(this).attr('data-old', 'latest');
			}
			if(this.value == 'oldest' && old != 'oldest'){
				$('.accordion-item').sort(function(a,b){
					return parseInt($(a).data("careerdate")) < parseInt($(b).data("careerdate"));
				}).each(function(){
					$("#post-type-career-list").prepend(this);
				});
				$(this).attr('data-old', 'oldest');
			}
		});


    
    	$(window).scroll(function() {    
    		var scroll = $(window).scrollTop();

    		if (scroll >= 1) {
    			$(".header").addClass("active");
    			$("body").addClass("active");
    		} else {
    			$(".header").removeClass("active");
    			$("body").removeClass("active");
    		}
    	});
    	if ($(window).scrollTop() > 1 ) {
    		$(".header").addClass("active");
    		$("body").addClass("active");
    	}

    	$(".slider-section div[class*='korner-slider-'] ").slick({
    		cssEase: 'ease',
    		fade: false,
    		arrows: false,
    		dots: true,
    		infinite: false,
    		speed: 500,
    		autoplay: true,
    		autoplaySpeed: 7000,
    		slidesToShow: 1,
    		slidesToScroll: 1,
    		// slide: '.slider-item',
    	});

    	$("#map-poland").CSSMap({
    		"size": 960
    	});

        // CSSMap;
        $("#map-europe").CSSMap({
        	"size": 1450,
        	"tooltips": "floating",
        	"responsive": "auto",
        	"tapOnce": true,
        	"agentsList": {
        		"enable": true,
        		"agentsListId": "#demo-agents",
        		"agentsListSpeed": 0,
        		"agentsListOnHover": false
        	},
        	'onClick' : function(e){
        		var rLink = e.children("a").eq(0).attr("href");
        			rText = e.children("A").eq(0).text(),
        			rClass = e.attr("class").split(" ")[0];
        			$("html, body").animate({ scrollTop: $(''+rLink).offset().top -50 }, 1000);
        		}
        	});




        // END OF THE CSSMap;

        $('.search-products-cat__form-select').each(function () {
        	var tthis = $(this);
        	var sel = $('select', tthis);
        	var label = sel.attr('data-label');

        	var options = '<ul>';
        	$('option', sel).each(function () {
        		options += '<li data-value="' + $(this).val() + '">' + $(this).text() + '</li>';
        	});
        	options += '</li>';

        	tthis.append('<div class="js-search-select"><div class="js-search-select__label"><span>' + label + '</span></div><div class="js-search-select__options">' + options + '</div></div>')
        });

        $('body').on('click', '.js-search-select__label', function () {
        	var parent = $(this).parents('.js-search-select');
        	parent.toggleClass('js-search-select--active');
        });

        $('body').on('click', '.js-search-select li', function () {
        	var parent = $(this).parents('.js-search-select');
        	var parent2 = $(this).parents('.search-products-cat__form-select');
        	var sel = $('select', parent2);
        	var val = $(this).attr('data-value');
        	var label = $(this).text();
        	parent.removeClass('js-search-select--active');
        	$('option[value!="' + val + '"]', sel).prop('selected', false);
        	$('option[value="' + val + '"]', sel).prop('selected', true);
        	$('.js-search-select__li--active', parent).removeClass('js-search-select__li--active');
        	$(this).addClass('js-search-select__li--active');
        	$('.js-search-select__label span').text(label);
        });

        function getAjaxRim(paged, parent) {
        	var name = '';
        	var type = '';
        	var company = 'all';
        	var loadBox = $('.search-products-cat-ajax-load', parent);

        	if ($('select[name="company"] option:checked', parent).length) {
        		company = $('select[name="company"] option:checked', parent).val();
        	}

        	if ($('input[name="type"]:checked', parent).length) {
        		type = $('input[name="type"]:checked', parent).val();
        	}

        	if ($('input[name="name"]', parent).length) {
        		name = $('input[name="name"]', parent).val();
        	}

        	var data = {
        		'action' : 'get_rim',
        		'paged' : paged,
        		'name' : name,
        		'type' : type,
        		'company' : company
        	};

        	loadBox.append('<div class="search-products-cat-ajax-loading"></div>');
        	$.post(php_array.admin_ajax, data, function(result){
        		loadBox.html(result);
        		rimClick();
        	});
        }

        $('body').on('click', '.search-products-pagination__page', function () {

        	var parent = $(this).parents('.search-products-pagination__pages');

        	$('.search-products-pagination__page--active', parent).removeClass('search-products-pagination__page--active');
        	$(this).addClass('search-products-pagination__page--active');

        	var paged = $(this).attr('data-lp');
        	var parent = $(this).parents('.search-products-cat-ajax');
        	getAjaxRim(paged, parent);
        });

        $('body').on('change', '.search-products-cat__form-radio input', function () {
        	var parent = $(this).parents('.search-products-cat-ajax');
        	getAjaxRim(1, parent);
        });

        $('body').on('submit', '.search-products-cat__form', function (e) {
        	e.preventDefault();
        	var parent = $(this).parents('.search-products-cat-ajax');
        	getAjaxRim(1, parent);
        	return false;
        });

    });

// Scripts which runs after all elements load

$(window).on( 'load', function () {

    //jQuery code goes here
    $('.preloader').addClass('preloader--hidden');

    new WOW().init();

    // function scrollToRim() {
    // 	if ( localStorage.hasOwnProperty('rim') ) {
    // 		var rim = localStorage.getItem('rim');
    // 		var headerHeight = $('header').outerHeight(true);
    // 		localStorage.removeItem('rim');
    // 		$('.accordion-item-title[data-auto-rim="true"]').click();
    // 		$('html, body').delay(500).animate({
    // 			scrollTop: $('.acordion-gallery-item[data-slug="'+rim+'"]').offset().top-(headerHeight + 10)
    // 		}, 1000);
    // 	}
    // }
    // scrollToRim();

});

// Scripts which runs at window resize

$(window).resize(function () {

    //jQuery code goes here

});
}(jQuery));

},{}]},{},[1])